import React from "react";
import { classNames } from "../tools/react.dom.helpers";
import ICON from "./const/icon";
import {_t} from "../../../js/translation";

const Icon = ({icon, type = Icon.TYPE.LIGHT, color, animate, title, className}) =>
    <i className={classNames(
        className,
        'icon', 
        type,
        color && 'text-' + color, 
        icon && 'fa-' + icon,
        animate && 'fa-' + animate,
    )} title={title && _t(title)} />

Icon.TYPE = {
    REGULAR: 'fa',
    SOLID: 'fas',
    BOLD: 'far',
    LIGHT: 'fal',
    BRANDS: 'fab',
}
Icon.COLOR = {
    DANGER: 'danger',
    SUCCESS: 'success',
    WARNING: 'warning',
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    ICON: 'icon'
}
Icon.ANIMATE = {
    SPIN: 'spin',
    PULSE: 'pulse'
}
Icon.ICON = ICON;

export default Icon;